<template>
  <div class="change-user-details">
    <div v-if="!pageEmailConfirm">
      <h1 class="h4 text-center mb-6">Change details</h1>
      <div class="selects-data">
        <div class="select-email">
          <v-checkbox
            v-model="showEmail"
            label="New email"
            hide-details
          />
        </div>
        <div class="select-phone">
          <v-checkbox
            v-model="showPhone"
            label="New Phone number"
            hide-details
            input-value="1"
          />
        </div>
      </div>
      <br/>
      <div class="form-change-details">
        <form class="form">
          <BaseInput
            v-if="showEmail"
            class="form-group"
            placeholder="Email"
            v-model="formData.email"
          />
          <InputTel
            v-if="showPhone"
            v-model="formData.phone"
            :diaCode="formData.diaCode"
            :validPhone="$v.formData.phone"
            @onDiaCode="countryChanged"
            :placeHolder="configEnv.onboarding.placeholderPhoneRegistration"
            :defaultCountry="getCountryCode"
            :autoDefaultCountry="true"
            :enableCountryCode="false"
          />
          <div class="form-group form-group_submit" v-if="showEmail || showPhone">
            <button
              class="button button_w-100 button_theme-default button_size-m"
              @click.prevent="updateUserData"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
    <EmailConfirmation v-else :objectRequest="objectRequest" />
  </div>
</template>

<script>
import BaseInput from '@innerworks_ds/innerworks-storybook-main/src/stories/CustomInputs/BaseInput/BaseInput.vue';
import configEnv from '@configEnv';
import { validationMixin } from 'vuelidate';
import EmailConfirmation from '@components/UserDetailsComponents/EmailConfirmation.vue';
import helpFunction from '@helpers/helpFunction';

const { required } = require('vuelidate/lib/validators');

export default {
  name: 'ChangeUserDetailsForm',
  mixins: [validationMixin],
  components: {
    EmailConfirmation,
    BaseInput,
  },
  props: {
    // oldPhoneNumber: {
    //   types: String,
    // },
    oldEmail: {
      types: String,
    },
  },
  data: () => ({
    configEnv,
    showEmail: true,
    showPhone: false,
    updatedTimeOut: false,
    smsCode: null,
    pageEmailConfirm: false,
    objectRequest: null,
    formData: {
      phone: '',
      email: null,
      diaCode: '',
      isoCountryCode: '',
    },
  }),
  validations: {
    formData: {
      phone: {
        required,
      },
      email: {
        required,
        mustBeCool: helpFunction.mustBeCool,
      },
    },
  },
  watch: {
    formData: {
      // eslint-disable-next-line no-unused-vars
      handler() {
        if (this.formData.phone.length > 5) {
          this.updatePhoneData();
        }
      },
      deep: true,
    },
  },
  computed: {
    getCountryCode() {
      return configEnv.onboarding.defaultStatePhone;
    },
  },
  methods: {
    countryChanged(data) {
      this.formData.isoCountryCode = data.iso2;
      this.formData.diaCode = data.dialCode;
    },
    updatePhoneData() {
      clearTimeout(this.updatedTimeOut);
      this.updatedTimeOut = setTimeout(() => {
        if (this.formData.phone && this.formData.diaCode) {
          this.formData.phone = this.formData.phone.replace(`+${this.formData.diaCode}`, '');
        }
      }, 50);
    },
    async updateUserData() {
      this.$emit('set-loading', true);
      if (!this.showPhone) {
        this.formData.phone = '';
      }

      if (!this.showEmail) {
        this.formData.email = '';
      }
      const formPhone = this.formData.phone;

      let data = {
        oldEmail: this.oldEmail,
        // oldPhoneNumber: this.oldPhoneNumber,
      };
      const s = this.formData.phone.replace(`+${this.formData.diaCode}`, '');

      const newPhoneNumber = `+${this.formData.diaCode}${formPhone.charAt(0) === '0' ? formPhone.substring(1) : formPhone}`.replace(/\s/g, '');

      try {
        if (s && this.formData.email) {
          data = {
            // oldPhoneNumber: this.oldPhoneNumber,
            oldEmail: this.oldEmail,
            newEmail: this.formData.email.toLowerCase(),
            newPhoneNumber,
            codeCountry: `+${this.formData.diaCode}`,
            isoCountryCode: this.formData.isoCountryCode,
          };
          await this.$api.auth.sendCodeToCheckEmail(data);
          this.pageEmailConfirm = true;
          this.objectRequest = data;
        } else if (this.formData.email) {
          data.newEmail = this.formData.email.toLowerCase();
          await this.$api.auth.sendCodeToCheckEmail(data);
          this.pageEmailConfirm = true;
          this.objectRequest = data;
        } else if (s) {
          data.codeCountry = `+${this.formData.diaCode}`;
          data.isoCountryCode = this.formData.isoCountryCode;
          data.newPhoneNumber = newPhoneNumber;
          await this.$api.auth.updateUserDetails(data);
          this.$emit('set-loading', false);
          this.$router.push({ path: '/login' });
        }
        this.$emit('set-loading', false);
      } catch (error) {
        this.$emit('set-loading', false);
      }
    },
  },
};
</script>

<style lang="scss">
  .change-user-details .v-label {
    margin: 0;
  }

  .change-user-details .v-input--selection-controls__input .v-icon {
    color: $bgCardColor1;
  }

  .change-user-details .v-input--selection-controls__ripple {
    color: $bgCardColor1 !important;
  }

  .select-phone {
    display: inline-block;
  }
  .select-email {
    display: inline-block;
    margin-right: 10%;
  }
  .form-change-details {
    width: 100%;
  }
  .selects-data {
    height: 100%;
    width: 100%;
  }
</style>
